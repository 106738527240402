<template>
    <div class="principal">
        <LoaderDefault v-if="isLoading" />
        <div v-if="!isLoading" class="page-header back-image"></div>
        <div v-if="!isLoading" class="col-md-1">
            <div class="wrapper-content bg-white pinside40">
                <div class="container-transparencia">
                    <a-col class="a-col-mb" align="center">
                        <h2 class="title-mp">
                            TRANSPARENCIA Y ACCESO A LA INFORMACIÓN PÚBLICA</h2>
                        <div class="desc-col-new">
                            <p class="text-sub">
                                Encontrarás información de las diferentes prácticas de transparencia en la gestión que
                                realiza EMSILVIA, de acuerdo a la ley 1712 del año 2014 y a la resolución 1519 del año 2020
                                y en
                                atención al cumplimiento e implementación del índice de transparencia.

                            </p>
                        </div>
                    </a-col>

                    <a-row clasS="a-col-mb" justify="center">
                        <div v-for="(item, indexOne) in taips" :key="indexOne">
                            <div id="panel-default-transp" class="panel panel-default-transp">
                                <a-col id="panel-heading-transp" class="panel-heading-transp" role="tab">
                                    <h4 class="panel-title">
                                        <a id="collapseOne" class="collapseOne">
                                            {{ item.title }}
                                            <span id="icono-mas" class="icono-mas">+</span>
                                        </a>
                                    </h4>
                                </a-col>
                                <a-col aria-labelledby="headingOne" class="panel-collapse collapse show2" role="tabpanel">
                                    <div id="panel-body" class="panel-body">
                                        <ul v-for="(dataTwo, indexTwo) in item.data" :key="indexTwo">
                                            <li class="text5">{{ dataTwo.subtitle }}
                                                <ul v-for="(itemTree, indexTree) in dataTwo.data" :key="indexTree">
                                                    <li class="text5">
                                                        <h6 class="btn-Text" href="" title="1.1.1 Misión y Visión"
                                                            v-if="itemTree.title === '1.1.1 Misión y Visión'"
                                                            aria-label="1.1.1 Misión y Visión">
                                                            <router-link to="/misionVision">
                                                                {{ itemTree.title }}
                                                            </router-link>
                                                        </h6>

                                                        <h6 v-else class="btn-Text" href="" title="1.1.1 Misión y Visión"
                                                            aria-label="1.1.1 Misión y Visión"
                                                            @click="openPdf(itemTree.urlPdf)">
                                                            {{ itemTree.title }}
                                                        </h6>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </a-col>
                            </div>
                        </div>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../../../../firebaseConfig';
import LoaderDefault from '../../../components/Loaders/LoaderDefault'

export default {
    components: {
        LoaderDefault
    },
    data() {
        return {
            isLoading: false,
            taips: [],
            listTaip: [],
            dataitemTaip: {
                title: "",
                urlPdf: ""
            }
        }
    },
    async mounted() {
        await this.getTaip();
        this.actionsNew()
    },
    methods: {
        async getTaip() {
            try {
                this.isLoading = true;
                const collectionRef = db.collection("taips").orderBy("createAt", "asc");
                const querySnapshot = await collectionRef.get();
                const listTaip = [];
                querySnapshot.forEach((doc) => {
                    listTaip.push(doc.data());
                });
                this.taips = listTaip;
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                throw error;
            }
        },


        getElementsIconMore() {
            const iconMore = document.getElementById("icono-mas")
            const panelBody = document.getElementById("panel-body")
            const colorGreen = document.getElementById("collapseOne")
            panelBody?.classList.toggle("panel-body-inactive")
            colorGreen.style.backgroundColor = "transparent"
            colorGreen.style.color = "inherit"
            iconMore.textContent = "+"

            iconMore?.addEventListener('click', (e) => {
                const panelBody = document.getElementById("panel-body")
                panelBody?.classList.toggle("panel-body-inactive")
                colorGreen.style.backgroundColor = "transparent"


                var currentValue = iconMore.textContent
                if (currentValue === "-") {
                    colorGreen.style.backgroundColor = "transparent"
                    colorGreen.style.color = "inherit"
                    iconMore.textContent = "+"
                } else if (currentValue === "+") {
                    iconMore.textContent = "-"
                    colorGreen.style.backgroundColor = "#45A825"

                    colorGreen.style.color = "white"
                }
            })
        },

        actionsNew() {
            const iconMore = document.querySelectorAll(".icono-mas")
            const panelBody = document.querySelectorAll(".panel-body")
            const panelTitle = document.querySelectorAll(".panel-title")

            iconMore.forEach((name, index) => {
                panelBody[index].classList.remove('panel-body-inactive')

                iconMore[index].addEventListener('click', () => {
                    var currentValue = iconMore[index].textContent
                    if (currentValue === "-") {
                        iconMore[index].textContent = "+"
                        panelTitle[index].classList.remove('panel-title-active')
                    } else if (currentValue === "+") {
                        iconMore[index].textContent = "-"
                        panelTitle[index].classList.add('panel-title-active')
                    }
                    panelBody[index].classList.toggle('panel-body-inactive')
                })
            })
        },

        openPdf(urlPdf) { window.open(urlPdf, '_blank'); }
    },

}


</script>
<style lang="css">
.back-image {
    background: url('../../../assets/img/fotoTransparencia.png') no-repeat center;
    background-size: cover;

}

/*subir archivos */

.panel {
    border-width: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border: 1px solid transparent;
}

.panel-default-transp>.panel-heading-transp {
    color: #333;
    background-color: #f4f4f4;
    border-color: #e4e5e7;
    padding: 20px 10px;
    user-select: none;
}

@media(max-width: 900px) {
    .panel-default-transp>.panel-heading-transp {
        padding: 5px;

    }
}



.panel-title {
    font-size: 1.1em;
    font-weight: 700;
    word-wrap: break-word;
    margin-top: 0;
    margin-bottom: 0;
    color: inherit;
    text-align: left;

}



.panel-title-active {
    background-color: var(--colorGreenPrimary) important;
    color: white important;
    border-radius: 4px;
}

.panel-default-transp>.panel-heading-transp a {
    display: block;
    padding: 10px 15px;
}



.panel-title>a {
    color: inherit;
}


.icono-mas {
    position: relative;
    top: -7px;
    display: inline-block;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    float: right;
    font-size: 30px;
}

/* subir mision y vision */

.text5 {
    list-style: none;
    line-height: 40px;
    font-weight: 800;
    padding-left: 10px;
}

.panel-body {
    padding: 15px;
    display: none;
}

@media(max-width: 400px) {
    .panel-body {
        padding: 5px;

    }

    .panel-body ul {
        margin: 0px;
        padding: 2px;
    }

}

.panel-body-inactive {
    display: inline-block;
}

.btn-Text {
    cursor: pointer;
    color: #777;

}
</style>